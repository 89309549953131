import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {DlcBtnActionState, DlcSaveBtnState} from '@gigasoftware/shared/api';
import {
  selectQuizSaveBtnState,
  updateQuizActionState
} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-quiz-save-btn'
  },
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatProgressSpinner
  ],
  selector: 'ec-save-quiz-btn',
  styleUrl: './ec-quiz-save-btn.component.scss',
  templateUrl: './ec-quiz-save-btn.component.html'
})
export class EcQuizSaveBtnComponent {
  private store = inject(Store);

  btnState: Signal<DlcSaveBtnState> = this.store.selectSignal(
    selectQuizSaveBtnState
  );

  isDisabled = computed(() => {
    const state = this.btnState();
    return (
      state === DlcSaveBtnState.DISABLED ||
      state === DlcSaveBtnState.IN_PROGRESS
    );
  });

  label = input('Save');

  saveInProgress = computed(() => {
    return this.btnState() === DlcSaveBtnState.IN_PROGRESS;
  });

  doSave = () => {
    this.store.dispatch(
      updateQuizActionState({
        state: DlcBtnActionState.DO_SAVE
      })
    );
  };
}
