import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {
  CollaborativeEntity,
  EC_QUIZ_ROLES,
  getRoleTitle
} from '@gigasoftware/shared/api';
import {
  MemberListItem,
  selectNgPatLoggedInUserCanDelete
} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {PushPipe} from '@ngrx/component';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {
  ConfirmDelete,
  EcConfirmDeleteDialogComponent
} from '../../dialogs/ec-confirm-delete-dialog/ec-confirm-delete-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-user-list'
  },
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    PushPipe,
    MatIconModule,
    MatTableModule
  ],
  selector: 'ec-user-list',
  styleUrls: ['./ec-user-list.component.scss'],
  templateUrl: './ec-user-list.component.html'
})
export class EcUserListComponent {
  private _collaborativeEntity$: BehaviorSubject<CollaborativeEntity | null> =
    new BehaviorSubject<CollaborativeEntity | null>(null);
  @Output() deleteAction: EventEmitter<MemberListItem> =
    new EventEmitter<MemberListItem>();

  displayedColumns: string[] = ['username', 'role', 'actions'];

  loggedInUserCanRemove$: Observable<boolean>;
  membersList: MemberListItem[] = [];
  @Input() ownerRoleNumber: number | undefined;
  roles = EC_QUIZ_ROLES;

  constructor(
    private _dialog: MatDialog,
    private store: Store,
    private _cd: ChangeDetectorRef
  ) {
    this.loggedInUserCanRemove$ = this._collaborativeEntity$.pipe(
      filter((c: CollaborativeEntity | null) => {
        return c !== null;
      }),
      switchMap(c => {
        if (c) {
          return this.store.pipe(
            select(selectNgPatLoggedInUserCanDelete(c.members))
          );
        } else {
          return of(false);
        }
      })
    );
  }

  canDelete$(user: MemberListItem): Observable<boolean> {
    return this.loggedInUserCanRemove$.pipe(
      map(
        (loggedInUserCanRemove: boolean) =>
          loggedInUserCanRemove && user.role !== EC_QUIZ_ROLES.Owner
      )
    );
  }

  getRoleTitle(roleNumber: number) {
    if (
      this.ownerRoleNumber !== undefined &&
      roleNumber === EC_QUIZ_ROLES.Owner
    ) {
      return getRoleTitle(this.ownerRoleNumber);
    }
    return getRoleTitle(roleNumber);
  }

  onDelete(u: MemberListItem) {
    const ref = this._dialog.open(EcConfirmDeleteDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: <ConfirmDelete>{
        name: u.username,
        showRemove: true
      }
    });

    ref.afterClosed().subscribe((doDelete: boolean) => {
      if (doDelete) {
        this.deleteAction.emit(u);
      }
    });
  }

  @Input()
  set collaborativeEntity(c: CollaborativeEntity | null | undefined) {
    if (c) {
      this._collaborativeEntity$.next(c);
    }
  }

  @Input()
  set members(m: MemberListItem[] | undefined) {
    if (m !== null && m !== undefined) {
      this.membersList = [...m];
      this._cd.markForCheck();
    }
  }
}
