import {NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {Route} from '@angular/router';
import {generate8CharCodeLowercase} from '@gigasoftware/shared/api';
import {
  NgPatPartialAccountStates,
  NgPatProviderIDAndEmail,
  ngPatSetGuardianCodeOnAccount,
  selectHasActiveSubscription,
  selectNgPatAccountLinkCode,
  selectNgPatHasMentorAccounts,
  selectNgPatProviderIDAndEmail
} from '@gigasoftware/shared/store';
import {
  DlcHeaderBarComponent,
  DlcMobilePageContentComponent,
  DlcRoundedTextIconButtonComponent,
  fadeInAnimation
} from '@gigasoftware/shared/ui-design-library';
import {LetDirective, PushPipe} from '@ngrx/component';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {EcCopyJoinCodeComponent} from '../../../components/ec-copy-join-code/ec-copy-join-code.component';
import {UiUsernameComponent} from '../../../components/username/ui-username.component';
import {PlatLinkAccountsComponent} from '../../../dialogs/plat-link-accounts/plat-link-accounts.component';
import {MentorListComponent} from '../../../lists/mentor-list/mentor-list.component';
import {MentoringMeListComponent} from '../../../lists/mentoring-me-list/mentoring-me-list.component';

@Component({
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@fadeInAnimation]': '',
    class: 'ec-profile-small-page dlc-global-mobile-page'
  },
  imports: [
    LetDirective,
    MatDividerModule,
    UiUsernameComponent,
    NgIf,
    MatButtonModule,
    MatIconModule,
    PushPipe,
    EcCopyJoinCodeComponent,
    MentorListComponent,
    MentoringMeListComponent,
    DlcRoundedTextIconButtonComponent,
    DlcHeaderBarComponent,
    DlcMobilePageContentComponent
  ],
  selector: 'ec-profile-small-page',
  styleUrls: ['./profile-small-page.component.scss'],
  templateUrl: './profile-small-page.component.html'
})
export class ProfileSmallPageComponent implements OnDestroy {
  private _store: Store<NgPatPartialAccountStates> = inject(Store);

  private _onDestroy$: Subject<boolean> = new Subject();
  accountMentorCode$: Observable<string>;

  hasMentorAccounts$: Observable<boolean>;
  hasMentoringMeAccounts$: Observable<boolean>;
  loggedInUserCanLinkAccount$: Observable<boolean> = this._store.select(
    selectHasActiveSubscription
  );
  loggedInUserIsStudent$: Observable<boolean> = this._store.select(
    selectHasActiveSubscription
  );
  selectProviderIDAndEmail$: Observable<NgPatProviderIDAndEmail>;
  showCheckoutProgress = false;

  constructor(private _cd: ChangeDetectorRef, private _dialog: MatDialog) {
    this.selectProviderIDAndEmail$ = this._store.pipe(
      select(selectNgPatProviderIDAndEmail)
    );

    this.accountMentorCode$ = this._store.pipe(
      select(selectNgPatAccountLinkCode),
      take(1),
      map((mentorCode: string | null) => {
        const _code: string = mentorCode
          ? mentorCode
          : generate8CharCodeLowercase();

        if (!mentorCode) {
          this._setGuardianCode(_code);
        }

        return _code;
      })
    );

    this.hasMentorAccounts$ = this._store.pipe(
      select(selectNgPatHasMentorAccounts)
    );
    this.hasMentoringMeAccounts$ = this._store.pipe(
      select(selectNgPatHasMentorAccounts)
    );
  }

  private _setGuardianCode(code: string) {
    this._store.dispatch(ngPatSetGuardianCodeOnAccount({code}));
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  onLinkAccount() {
    this._dialog.open(PlatLinkAccountsComponent, {
      backdropClass: 'dlc-global-dialog-background'
    });
  }
}

export const EC_PROFILE_ROUTES: Route[] = [
  {
    component: ProfileSmallPageComponent,
    path: ''
  }
];
