import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {Answer} from '@gigasoftware/shared/api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-multiple-choice-answer'
  },
  imports: [CommonModule],
  selector: 'ec-multiple-choice-answer',
  styleUrls: ['./ec-multiple-choice-summary.component.scss'],
  templateUrl: './ec-multiple-choice-summary.component.html'
})
export class EcMultipleChoiceSummaryComponent {
  answerSignal: WritableSignal<Answer | null> = signal<Answer | null>(null);

  isCorrect: Signal<boolean> = computed(() => {
    return this.answerSignal()?.isCorrect === true;
  });

  answerText: Signal<string> = computed(() => {
    const answer: Answer | undefined | null = this.answerSignal();
    if (answer) {
      return answer.answerText;
    }

    return '';
  });

  @Input()
  set answer(value: Answer | null) {
    this.answerSignal.set(value);
  }
}
