import {EC_QUIZ_ROLES} from '@gigasoftware/shared/api';

export interface JoinEntityTypeDisplay {
  displayValue: string;
  role: EC_QUIZ_ROLES;
}

export interface JoinGroupForm {
  joinCode: string;
  role: EC_QUIZ_ROLES;
}

export interface JoinEntityComponentState {
  entityType: number;
  error: string;
  hasError: boolean;
  inProgress: boolean;
}

export interface JoinDialogData {
  entityTypeID: number;
  title: string;
}

export enum JOIN_CLASS_ERROR {
  NOT_FOUND = 'notFound',
  IS_JOINED = 'isJoined',
  ERROR = 'error'
}
