import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {
  AlgoliaPublishedQuiz,
  CollaborativeEntity,
  CreateQuizParams,
  GradeLevel,
  Quiz
} from '@gigasoftware/shared/api';
import {
  getGradeLevelNumber,
  GRADE_LEVELS,
  QuizCreatorComponentService
} from '@gigasoftware/shared/store';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {BehaviorSubject, from, Observable, Subject, switchMap} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';

import {EcCreateQuizComponentModel} from './ec-create-quiz.component.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-form-create-quiz'
  },
  imports: [
    CommonModule,
    DlcRoundedTextButtonComponent,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  providers: [QuizCreatorComponentService],
  selector: 'ec-create-quiz',
  styleUrls: ['./ec-create-quiz.component.scss'],
  templateUrl: './ec-create-quiz.component.html'
})
export class EcCreateQuizComponent implements OnInit, OnDestroy {
  quizCreatorComponentService = inject(QuizCreatorComponentService);
  private fb: FormBuilder = inject(FormBuilder);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  private announcer: LiveAnnouncer = inject(LiveAnnouncer);

  @Output() createAction: EventEmitter<CreateQuizParams> =
    new EventEmitter<CreateQuizParams>();
  filteredGradeLevels!: Observable<GradeLevel[]>;
  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;
  // GRADE LEVELS
  gradeLevels: GradeLevel[] = GRADE_LEVELS;
  nameFormGroup: FormGroup;
  @Input() parentEntity: CollaborativeEntity | null | undefined = null;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() showAggregateFalseAnswersOption = false;
  @Input() showAssignedOption = false;
  showCollaborationOption = false;
  @Input() showCollaborativeOption = false;
  showEntityTypeSelect$: BehaviorSubject<boolean>;
  @Input() showIsPrivate = false;
  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;
  private _onDestroy$: Subject<boolean> = new Subject();
  private _quiz: Quiz | null = null;

  constructor() {
    this.nameFormGroup = this.fb.group({
      aggregateFalseAnswers: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.aggregateFalseAnswers
      ),
      assigned: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.assigned
      ),
      description: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.description
      ),
      gradeLevel: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.gradeLevel
      ),
      isCollaborative: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.isCollaborative
      ),
      isPrivate: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.isPrivate
      ),
      name: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.name,
        [Validators.required]
      ),
      subjects: new FormControl(
        this.quizCreatorComponentService.defaultQuizParams.subjects
      )
    });

    this.showEntityTypeSelect$ = new BehaviorSubject<boolean>(true);

    this.nameFormGroup.valueChanges
      .pipe(takeUntilDestroyed(), debounceTime(300))
      .subscribe((value: EcCreateQuizComponentModel) => {
        // subjects are handled by the Algolia service
        delete value.subjects;

        this.quizCreatorComponentService.patchState({
          ...value,
          gradeLevel: getGradeLevelNumber(value.gradeLevel)
        });
      });
  }

  get gradeLevelControl() {
    return this.nameFormGroup.controls['gradeLevel'];
  }

  get hasParentEntity() {
    return this.parentEntity !== null && this.parentEntity !== undefined;
  }

  @Input()
  set quiz(q: Quiz | null) {
    this._quiz = q;
  }

  get showPrivateOption() {
    return this.nameFormGroup.value.isCollaborative;
  }

  get subjectControl() {
    return this.nameFormGroup.controls['subjects'];
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.quizCreatorComponentService.addAlgoliaSubjectAutocomplete(event);
    this.subjectControl.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    this.quizCreatorComponentService.removeAlgoliaSubjectAutocomplete(subject);
    this.announcer.announce(`Removed ${subject}`);
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.quizCreatorComponentService.selectAlgoliaSubjectAutocomplete(event);
    this.subjectInput.nativeElement.value = '';
    this.subjectControl.setValue(null);
  }

  // GRADE LEVELS
  displayFn(user: GradeLevel): string {
    return user && user.name ? user.name : '';
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit(): void {
    if (this.parentEntity) {
      this.quizCreatorComponentService.setParentEntity(this.parentEntity);
    }

    this.showCollaborationOption =
      !this.hasParentEntity && this.showCollaborativeOption;
    this.nameFormGroup.controls['isCollaborative'].setValue(
      this.parentEntity !== null && this.parentEntity !== undefined
    );

    // GRADE LEVELS
    this.filteredGradeLevels = this.gradeLevelControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.gradeLevels.slice();
      })
    );

    // END GRADE LEVELS

    // AUTOCOMPLETE FOR SUBJECTS

    this.filteredSubjects = this.subjectControl.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string | null) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.quizCreatorComponentService.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(
            subject
          );
        }
        return from([]);
      })
    );

    // END AUTOCOMPLETE FOR SUBJECTS
    this.cd.detectChanges();
  }

  onSubmit() {
    if (this.nameFormGroup.valid) {
      this.quizCreatorComponentService.createQuiz();
      this.createAction.emit(this.quizCreatorComponentService.state());
    }
  }

  private _filter(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // END AUTOCOMPLETE FOR SUBJECTS
}
