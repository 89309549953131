import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output
} from '@angular/core';
import {Classroom, CollaborativeEntity} from '@gigasoftware/shared/api';
import {updateClassroomEffect} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';

import {EcClassListItemComponent} from './ec-class-list-item/ec-class-list-item.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-class-list'
  },
  imports: [CommonModule, EcClassListItemComponent],
  selector: 'ec-class-list',
  styleUrls: ['./ec-class-list.component.scss'],
  templateUrl: './ec-class-list.component.html'
})
export class EcClassListComponent {
  @Input() classes: Classroom[] = [];

  @Output() doDeleteItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  @Output() doEditItem: EventEmitter<Classroom> = new EventEmitter<Classroom>();

  @Output() doLeaveItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  @Output() doSelectItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  constructor(
    private _cd: ChangeDetectorRef,
    private _zone: NgZone,
    private store: Store
  ) {}

  onJoinGroup(p: CollaborativeEntity) {
    this._zone.run(() => {
      this.store.dispatch(
        updateClassroomEffect({
          classroom: p
        })
      );
    });
  }
}
