import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
} from '@gigasoftware/shared/api';

import {JoinGroupWithCodeDialogComponent} from './join-group-with-code-dialog/join-group-with-code-dialog.component';
import {JoinDialogData} from './join-group-with-code-dialog/join-group-with-code-dialog.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [MatButtonModule, MatIconModule],
  selector: 'ec-join-group-with-code',
  styleUrls: ['./join-group-with-code.component.scss'],
  templateUrl: './join-group-with-code.component.html'
})
export class JoinGroupWithCodeComponent {
  @Output() doJoinGroup: EventEmitter<CollaborativeEntity> =
    new EventEmitter<CollaborativeEntity>();
  @Input() entityType = DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id;
  @Input() title = 'Group';

  constructor(private _dialog: MatDialog) {}

  openDialog() {
    const ref = this._dialog.open(JoinGroupWithCodeDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: <JoinDialogData>{
        entityTypeID: this.entityType,
        title: this.title
      }
    });

    ref.afterClosed().subscribe((p: CollaborativeEntity) => {
      if (p) {
        this.doJoinGroup.emit(p);
      }
    });
  }
}
