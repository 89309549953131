import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  output,
  Output,
  OutputEmitterRef,
  signal,
  Signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import {MatMiniFabButton} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {
  AlgoliaPublishedQuiz,
  DlcBtnActionState,
  DlcSaveBtnState,
  GradeLevel,
  Question,
  QuestionAction,
  Quiz
} from '@gigasoftware/shared/api';
import {
  GsAssetService,
  GSFirebaseUploadImageWithDocConfig,
  GsFirestoreUpdateDoc
} from '@gigasoftware/shared/media';
import {
  DlcInputImageConfig,
  ecQuizImageUploaderConfig,
  getGradeLevel,
  getGradeLevelNumber,
  GRADE_LEVELS,
  QuizCreatorComponentService,
  QuizQueryEngine,
  QuizzesEngineStore,
  selectQuizBtnActionState,
  updateFirestorePartialQuiz,
  updateQuizSaveBtnState
} from '@gigasoftware/shared/store';
import {
  DlcInputImageComponent,
  DlcLabelContainerComponent,
  mapFormControlStatusToSaveBtnState
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';
import {from, Observable, Subject, switchMap} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  takeUntil
} from 'rxjs/operators';

import {EcEditQuizComponentModel} from './ec-edit-quiz.component.model';
import {EcQuestionSummaryComponent} from './ec-question-summary/ec-question-summary.component';

interface EditQuizForm {
  aggregateFalseAnswers: boolean;
  description: string;
  gradeLevel: number | null;
  name: string;
  subjects: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-quiz-edit'
  },
  imports: [
    CommonModule,
    DlcInputImageComponent,
    EcQuestionSummaryComponent,
    DlcLabelContainerComponent,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatMiniFabButton
  ],
  providers: [GsAssetService, QuizCreatorComponentService],
  selector: 'ec-quiz-edit',
  styleUrls: ['./ec-edit-quiz.component.scss'],
  templateUrl: './ec-edit-quiz.component.html'
})
export class EcEditQuizComponent implements OnInit, OnDestroy {
  quizCreatorComponentService = inject(QuizCreatorComponentService);
  private store = inject(Store);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  private announcer: LiveAnnouncer = inject(LiveAnnouncer);
  private quizStore: QuizzesEngineStore = inject(QuizzesEngineStore);
  private fb: FormBuilder = inject(FormBuilder);

  private _onDestroy$: Subject<boolean> = new Subject();

  editQuizFormGroup: FormGroup = this.fb.group({
    aggregateFalseAnswers: new FormControl<boolean>(true),
    description: new FormControl<string>(''),
    gradeLevel: new FormControl<number | null>(null),
    name: new FormControl<string>('', [
      Validators.minLength(3),
      Validators.required
    ]),
    subjects: new FormControl<string>('')
  });

  get description(): FormControl {
    return this.editQuizFormGroup.get('description') as FormControl;
  }

  get gradeLevel(): FormControl {
    return this.editQuizFormGroup.get('gradeLevel') as FormControl;
  }

  get name(): FormControl {
    return this.editQuizFormGroup.get('name') as FormControl;
  }

  get subjects(): FormControl {
    return this.editQuizFormGroup.get('subjects') as FormControl;
  }

  get aggregateFalseAnswers(): FormControl {
    return this.editQuizFormGroup.get('aggregateFalseAnswers') as FormControl;
  }

  @Output() doEditQuestion: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();

  doUpdateSaveBtnState: OutputEmitterRef<DlcSaveBtnState> =
    output<DlcSaveBtnState>();

  filteredGradeLevels!: Observable<GradeLevel[]>;
  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;
  gradeLevels: GradeLevel[] = GRADE_LEVELS;

  imageChangedEvent: WritableSignal<Event | null> = signal(null);

  @ViewChild(DlcInputImageComponent) inputImageComponent:
    | DlcInputImageComponent
    | undefined;

  quizFirestoreDocPath: Signal<string | null | undefined> = toSignal(
    this.quizStore.selectCurrentQuizFirestoreDocPath$
  );

  inputImageConfig: Signal<DlcInputImageConfig | null | undefined> = computed(
    () => {
      const firestoreDocPath: string | null | undefined =
        this.quizFirestoreDocPath();

      if (firestoreDocPath) {
        return {
          filenameWithoutExtension: this.quiz()?.id || '',
          imagePath: this.quiz()?.imagePath || '',
          uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
            ...ecQuizImageUploaderConfig,
            firestoreDoc: <GsFirestoreUpdateDoc>{
              docProperty: 'imagePath',
              firestoreDocPath
            }
          }
        };
      }

      return null;
    }
  );

  qe: Signal<QuizQueryEngine | null | undefined> = <
    Signal<QuizQueryEngine | null | undefined>
  >toSignal(this.quizStore.selectCurrentQuizQueryEngine$);

  questions: Signal<Question[]> = <Signal<Question[]>>(
    toSignal(this.quizStore.selectCurrentQuizQuestions$)
  );

  quiz: Signal<Quiz | null | undefined> = <Signal<Quiz | null | undefined>>(
    toSignal(this.quizStore.selectCurrentQuiz$)
  );

  // AUTOCOMPLETE FOR SUBJECTS
  separatorKeysCodes: number[] = [ENTER, COMMA];
  showID = false;

  // @depregacted - use editQuizFormGroup.subjects
  // subjectControl = new FormControl();
  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;

  constructor() {
    effect(
      () => {
        const quiz: Quiz | undefined | null = this.quiz();

        if (quiz) {
          this.quizCreatorComponentService.setEditQuiz(quiz);
          this.name.setValue(quiz.name, {emitEvent: false});
          this.description.setValue(quiz.description, {emitEvent: false});

          if (quiz.gradeLevel) {
            this.gradeLevel.setValue(getGradeLevel(quiz.gradeLevel), {
              emitEvent: false
            });
          }
          if (quiz.subjects) {
            this.quizCreatorComponentService.setManyCommaDelimitedAlgoliaSubjectAutocomplete(
              quiz.subjects
            );
          }
          this.aggregateFalseAnswers.setValue(quiz.aggregateFalseAnswers, {
            emitEvent: false
          });
        }
      },
      {allowSignalWrites: true}
    );

    this.quizCreatorComponentService.startLiveUpdateChanges();

    this.store
      .select(selectQuizBtnActionState)
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        filter(
          (state: DlcBtnActionState) => state === DlcBtnActionState.DO_SAVE
        )
      )
      .subscribe(() => {
        this.doSave();
      });
  }

  private _filterGradeLevels(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.quizCreatorComponentService.addAlgoliaSubjectAutocomplete(event);
    this.subjects.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    this.quizCreatorComponentService.removeAlgoliaSubjectAutocomplete(subject);
    this.announcer.announce(`Removed ${subject}`);
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.quizCreatorComponentService.selectAlgoliaSubjectAutocomplete(event);
    this.subjectInput.nativeElement.value = '';
    this.subjects.setValue(null);
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this.quizCreatorComponentService.destroy();
  }

  ngOnInit() {
    this.editQuizFormGroup.valueChanges
      .pipe(takeUntil(this._onDestroy$), debounceTime(300))
      .subscribe((value: EcEditQuizComponentModel) => {
        // subjects handled in the algoliaSubjectAutocomplete service
        delete value.subjects;
        this.quizCreatorComponentService.patchState({
          ...value,
          gradeLevel: getGradeLevelNumber(value.gradeLevel)
        });

        setTimeout(() => {
          this.doSave();
        }, 2000);
      });

    this.editQuizFormGroup.statusChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((status: FormControlStatus) => {
        // if (status === 'VALID') {
        //   this.doUpdateSaveBtnState.emit(DlcSaveBtnState.ACTIVE);
        // } else {
        //   this.doUpdateSaveBtnState.emit(DlcSaveBtnState.DISABLED);
        // }

        this.doUpdateSaveBtnState.emit(
          mapFormControlStatusToSaveBtnState(status)
        );

        this.store.dispatch(
          updateQuizSaveBtnState({
            state: mapFormControlStatusToSaveBtnState(status)
          })
        );
      });

    this.filteredSubjects = this.subjects.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string | null) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.quizCreatorComponentService.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(
            subject
          );
        }
        return from([]);
      })
    );

    // END AUTOCOMPLETE FOR SUBJECTS

    this.cd.detectChanges();
  }

  doSave() {
    this.doUpdateSaveBtnState.emit(DlcSaveBtnState.DISABLED);

    this.store.dispatch(
      updateQuizSaveBtnState({
        state: DlcSaveBtnState.DISABLED
      })
    );

    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz && this.editQuizFormGroup.valid) {
      this.quizCreatorComponentService.updateQuiz();
      this.editQuizFormGroup.reset(this.editQuizFormGroup.value, {
        emitEvent: false
      });
    }
  }

  // GRADE LEVELS

  // onAssignQuiz(c: MatCheckboxChange) {
  //   const quiz: Quiz | null | undefined = this.quiz();
  //
  //   if (quiz) {
  //     /**
  //      * TODO should this be called inside QuizQueryEngine?
  //      */
  //     this.store.dispatch(
  //       updateFirestorePartialQuiz({
  //         changes: <Quiz>{
  //           assigned: c.checked
  //         },
  //         quiz
  //       })
  //     );
  //   }
  // }

  clearNameForm() {
    this.name.setValue('');
    this.name.setErrors({required: true});
  }

  clearDescriptionForm() {
    this.description.setValue('');
    // this.name.setErrors({required: true});
  }

  onDeleteImage() {
    const that = this;
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      that.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>{
            imagePath: null
          },
          quiz
        })
      );
    }
  }

  // onEditQuizDescription(description: string) {
  //   const quiz: Quiz | null | undefined = this.quiz();
  //
  //   if (quiz) {
  //     this.store.dispatch(
  //       updateFirestorePartialQuiz({
  //         changes: <Quiz>{
  //           description
  //         },
  //         quiz
  //       })
  //     );
  //   }
  // }
  //
  // onEditQuizName(name: string) {
  //   const quiz: Quiz | null | undefined = this.quiz();
  //
  //   if (quiz) {
  //     this.store.dispatch(
  //       updateFirestorePartialQuiz({
  //         changes: <Quiz>{
  //           name
  //         },
  //         quiz
  //       })
  //     );
  //   }
  // }
  // END AUTOCOMPLETE FOR SUBJECTS
}
