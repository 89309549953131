import {NgForOf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {Classroom} from '@gigasoftware/shared/api';
import {
  ClassGradesService,
  StudentGradesTableData
} from '@gigasoftware/shared/store';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-class-grades'
  },
  imports: [MatTableModule, NgForOf],
  providers: [ClassGradesService],
  selector: 'ec-class-grades',
  styleUrls: ['./ec-class-grades.component.scss'],
  templateUrl: './ec-class-grades.component.html'
})
export class EcClassGradesComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  columnsToDisplay: string[] = [];

  dataSource: MatTableDataSource<StudentGradesTableData>;

  @Input() showTitles = true;
  constructor(private _grades: ClassGradesService) {
    this.dataSource = new MatTableDataSource<StudentGradesTableData>();

    combineLatest([
      this._grades.displayedColumns$,
      this._grades.studentGradesTableData$
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([displayedColumns, grades]: [string[], StudentGradesTableData[]]) => {
          this.columnsToDisplay = [...displayedColumns];
          this.dataSource.data = [...grades];

          // console.log(displayedColumns, grades);
        }
      );
  }

  ngOnDestroy() {
    this._grades.onDestroy();
    this._onDestroy$.next(true);
  }

  @Input()
  set classroom(s: Classroom | null | undefined) {
    if (s) {
      this._grades.init(s);
    }
  }
}
