import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {QuestionAction} from '@gigasoftware/shared/api';
import {QuizzesEngineStore} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  selector: 'ec-confirm-delete-question-and-assets',
  styleUrl: './confirm-delete-question-and-assets.component.scss',
  templateUrl: './confirm-delete-question-and-assets.component.html'
})
export class ConfirmDeleteQuestionAndAssetsComponent {
  deleteError: WritableSignal<string> = signal('');
  hasDeleteError: WritableSignal<boolean> = signal(false);
  isDeleting: WritableSignal<boolean> = signal(false);

  constructor(
    private quizStore: QuizzesEngineStore,
    public dialogRef: MatDialogRef<ConfirmDeleteQuestionAndAssetsComponent>,
    @Inject(MAT_DIALOG_DATA) public action: QuestionAction
  ) {}

  async onDeleteQuestionAndAssets() {
    this.isDeleting.set(true);
    return this.quizStore
      .onDeleteQuestion(this.action)
      .then(() => {
        this.isDeleting.set(false);
        this.dialogRef.close();
      })
      .catch((error: any) => {
        this.isDeleting.set(false);
        this.hasDeleteError.set(true);
        this.deleteError.set(error.message);
      });
  }
}
