import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {
  BaseEntityType,
  CollaborationEntityForm,
  CreateEntityParams,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  generateRandomString,
  getEntityTypeByName,
  PLATFORM_ENTITY_TYPES
} from '@gigasoftware/shared/api';
import {uuid} from '@gigasoftware/shared/fn';
import {createEntityParams} from '@gigasoftware/shared/store';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-create-research'
  },
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    DlcRoundedTextButtonComponent
  ],
  selector: 'ec-create-research',
  styleUrls: ['./ec-create-research.component.scss'],
  templateUrl: './ec-create-research.component.html'
})
export class EcCreateResearchComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  @Output() createAction: EventEmitter<CreateEntityParams> =
    new EventEmitter<CreateEntityParams>();
  entityTypeForm: FormControl = new FormControl(
    DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.name,
    [Validators.required]
  );
  entityTypes: BaseEntityType[] = PLATFORM_ENTITY_TYPES;
  entityTypeNames: string[] = this.entityTypes.map(
    (p: BaseEntityType) => p.name
  );
  nameFormGroup: FormGroup;
  selectEntityType: BaseEntityType = DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH;
  showEntityTypeSelect: WritableSignal<boolean> = signal(true);
  @Input() showIsPrivate = false;

  constructor(private store: Store, private _fb: FormBuilder) {
    this.nameFormGroup = this._fb.group({
      description: new FormControl(null),
      isCollaborative: new FormControl(true),
      isPrivate: new FormControl(false),
      name: new FormControl(null, [Validators.required])
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  onSubmit() {
    const params: CreateEntityParams = {
      entityTypeValue: getEntityTypeByName(this.entityTypeForm.value).id,
      id: uuid(),
      ...this.nameFormGroup.value,
      joinCode: generateRandomString(8, '', false)
    };

    this.store.dispatch(createEntityParams({params}));

    this.nameFormGroup.reset(
      <CollaborationEntityForm>{
        description: null,
        isCollaborative: this.nameFormGroup.value.isCollaborative,
        isPrivate: this.nameFormGroup.value.isPrivate,
        name: null
      },
      {emitEvent: false}
    );

    this.createAction.emit(params);
  }

  get entityTypeName() {
    return this.selectEntityType?.name;
  }
}
