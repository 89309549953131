import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {AlgoliaPublishedQuiz, GradeLevel, Quiz} from '@gigasoftware/shared/api';
import {
  AlgoliaSearchSubjects,
  getGradeLevel,
  GRADE_LEVELS,
  OpenAiGenerateQuizService,
  OpenAiQuizGeneratorForm
} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {combineLatest, from, Observable, Subject} from 'rxjs';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatDialogModule,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  providers: [AlgoliaSearchSubjects],
  selector: 'ec-ec-open-ai-generate-quiz-questions',
  styleUrl: './ec-open-ai-generate-quiz-questions.component.scss',
  templateUrl: './ec-open-ai-generate-quiz-questions.component.html'
})
export class EcOpenAiGenerateQuizQuestionsComponent
  implements OnInit, OnDestroy
{
  public openAiGenerateQuizService: OpenAiGenerateQuizService = inject(
    OpenAiGenerateQuizService
  );
  private fb: FormBuilder = inject(FormBuilder);

  private _onDestroy$: Subject<boolean> = new Subject();

  filteredGradeLevels!: Observable<GradeLevel[]>;

  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;

  // GRADE LEVELS
  gradeLevels: GradeLevel[] = GRADE_LEVELS;

  message: FormControl = new FormControl('', [Validators.required]);

  openAiForm: FormGroup = this.fb.group({
    description: new FormControl(''),
    gradeLevel: new FormControl(''),
    name: new FormControl(''),
    subjects: new FormControl('')
  });
  // AUTOCOMPLETE FOR SUBJECTS
  separatorKeysCodes: number[] = [ENTER, COMMA];

  showSpinner: Signal<boolean> = <Signal<boolean>>(
    toSignal(this.openAiGenerateQuizService.showSpinner$)
  );

  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;
  constructor(
    public algoliaSubjectAutocomplete: AlgoliaSearchSubjects,
    public dialogRef: MatDialogRef<EcOpenAiGenerateQuizQuestionsComponent>,
    private announcer: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public quiz: Quiz
  ) {}

  // GRADE LEVELS
  private _filterGradeLevels(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.algoliaSubjectAutocomplete.add(event, true);
    this.subjectControl.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    const index = this.algoliaSubjectAutocomplete.remove(subject, true);

    if (index >= 0) {
      this.announcer.announce(`Removed ${subject}`);
    }
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.algoliaSubjectAutocomplete.selected(event);
    this.subjectInput.nativeElement.value = '';
    this.subjectControl.setValue(null);
  }

  generateQuizQuestions(): void {
    this.openAiGenerateQuizService.generateQuizQuestions(
      this.quiz,
      this.message.value
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    const that = this;

    combineLatest([
      this.openAiForm.valueChanges,
      this.algoliaSubjectAutocomplete.valueChanges$.pipe(startWith(''))
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: ([form, subjects]: [OpenAiQuizGeneratorForm, string]) => {
          const formValue = {
            ...form,
            subjects: subjects
          };

          that.message.setValue(
            that.openAiGenerateQuizService.createMessage(formValue)
          );
          // that.openAiGenerateQuizService.generateQuizQuestions(that.quiz, that.openAiGenerateQuizService.createMessage(that.quiz, formValue));
        }
      });

    if (this.quiz.gradeLevel !== null && this.quiz.gradeLevel !== undefined) {
      this.gradeLevelControl.setValue(getGradeLevel(this.quiz.gradeLevel));
    }

    if (this.quiz.description) {
      this.description.setValue(this.quiz.description);
    }

    if (this.quiz.subjects) {
      this.algoliaSubjectAutocomplete.setManyCommaDelimited(
        this.quiz.subjects,
        true
      );
    }

    this.name.setValue(this.quiz.name);

    this.openAiGenerateQuizService.closeDialog$.subscribe({
      next: (doClose: boolean) => {
        if (doClose) {
          this.dialogRef.close();
        }
      }
    });

    // GRADE LEVELS
    this.filteredGradeLevels = this.gradeLevelControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filterGradeLevels(name as string)
          : this.gradeLevels.slice();
      })
    );

    /**
     * DO NOT SAVE TO FIRESTORE
     * THIS IS ONLY TO GENERATE A QUIZ
     */
    // this.gradeLevelControl.valueChanges.subscribe((gradeLevel: GradeLevel) => {
    //
    //   const quiz: Quiz | null | undefined = this.quiz();
    //
    //   if (quiz) {
    //     this.store.dispatch(
    //       updateFirestorePartialQuiz({
    //         changes: <Quiz>{
    //           gradeLevel: gradeLevel.level
    //         },
    //         quiz
    //       })
    //     );
    //   }
    // });

    // END GRADE LEVELS

    // AUTOCOMPLETE FOR SUBJECTS
    this.filteredSubjects = this.subjectControl.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string | null) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(
            subject
          );
        }
        return from([]);
      })
    );

    /**
     * DO NOT SAVE TO FIRESTORE
     * THIS IS ONLY TO GENERATE A QUIZ
     */
    // this.algoliaSubjectAutocomplete.valueChanges$.subscribe((ec-subjects: string) => {
    //   console.log(ec-subjects);
    //   const quiz: Quiz | null | undefined = this.quiz();
    //
    //   if (quiz) {
    //     this.algoliaSubjectAutocomplete.clear();
    //
    //     this.store.dispatch(
    //       updateFirestorePartialQuiz({
    //         changes: <Quiz>{
    //           ec-subjects: ec-subjects
    //         },
    //         quiz
    //       })
    //     );
    //
    //   }
    // })

    // END AUTOCOMPLETE FOR SUBJECTS
  }

  get description(): FormControl {
    return <FormControl>this.openAiForm.get('description');
  }

  get gradeLevelControl(): FormControl {
    return <FormControl>this.openAiForm.get('gradeLevel');
  }

  // END AUTOCOMPLETE FOR SUBJECTS

  get name(): FormControl {
    return <FormControl>this.openAiForm.get('name');
  }

  get subjectControl(): FormControl {
    return <FormControl>this.openAiForm.get('subjects');
  }
}
