import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Answer, QuestionWithAnswer, UserAnswer} from '@gigasoftware/shared/api';

import {QuizAnswerComponent} from '../../../button/quiz-answer/quiz-answer.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-answer-true-false answer__container'
  },
  imports: [QuizAnswerComponent],
  selector: 'ec-answer-true-false',
  styleUrls: ['../answers.scss'],
  templateUrl: './ec-answer-true-false.component.html'
})
export class EcAnswerTrueFalseComponent {
  @Output() answer: EventEmitter<QuestionWithAnswer> =
    new EventEmitter<QuestionWithAnswer>();

  @Input()
  question: QuestionWithAnswer | null | undefined;

  constructor(private _cd: ChangeDetectorRef) {}

  onTrueFalseAnswer(userAnswer: boolean) {
    if (this.question) {
      const answer: Answer = Object.values(this.question.question.answers)[0];

      const recordedAnswer: QuestionWithAnswer = {
        ...this.question,
        answers: {
          [answer.id]: <UserAnswer>{
            answer,
            multipleChoiceChecked: false,
            trueFalseAnswer: userAnswer
          }
        },
        isAnswered: true,
        isCorrect: answer.trueFalseAnswer === userAnswer
      };

      this.answer.emit(recordedAnswer);
    }
  }
}
