import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {Answer, QuestionWithAnswer, UserAnswer} from '@gigasoftware/shared/api';
import {shuffle} from '@gigasoftware/shared/fn';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {QuizAnswerComponent} from '../../../button/quiz-answer/quiz-answer.component';

interface MultiChoiceAnswer {
  answer: Answer;
  checked: boolean;
}

interface MultiChoiceForm {
  formArray: MultiChoiceAnswer[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-answer-multiple-choice answer__container'
  },
  imports: [
    CommonModule,
    QuizAnswerComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  selector: 'ec-answer-multiple-choice',
  styleUrls: ['../answers.scss'],
  templateUrl: './ec-answer-multiple-choice.component.html'
})
export class EcAnswerMultipleChoiceComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  @Output() answer: EventEmitter<QuestionWithAnswer> =
    new EventEmitter<QuestionWithAnswer>();

  hasMultipleAnswers: WritableSignal<boolean> = signal(false);

  question$: ReplaySubject<QuestionWithAnswer> =
    new ReplaySubject<QuestionWithAnswer>(1);

  questionForm: FormGroup;

  recordedAnswer: QuestionWithAnswer | null = null;

  constructor(private _fb: FormBuilder) {
    this.questionForm = this._fb.group({
      formArray: new FormArray([])
    });
  }

  getAnswerText(index: number) {
    const formControl: AbstractControl | null = (<FormGroup>(
      this.formArray.at(index)
    )).get('answer');
    return formControl ? formControl.value.answerText : '';
  }

  init(question: QuestionWithAnswer): void {
    let answers: Answer[] = [];

    /**
     * Collect all answers
     */
    for (const key in question.question.answers) {
      answers.push(question.question.answers[key]);
    }

    const numberCorrectAnswers: number = answers.reduce<number>(
      (acc: number, i: Answer) => {
        if (i.isCorrect) {
          return acc + 1;
        }

        return acc;
      },
      0
    );

    if (
      numberCorrectAnswers !== null &&
      numberCorrectAnswers !== undefined &&
      numberCorrectAnswers > 1
    ) {
      this.hasMultipleAnswers.set(true);
    }

    /**
     * Randomize order of answers
     */
    if (answers.length > 2) {
      answers = shuffle(answers);
    }

    this.formArray.clear({emitEvent: false});

    for (let i = 0; i < answers.length; i++) {
      this.formArray.push(
        this._fb.group({
          answer: new FormControl(answers[i]),
          checked: new FormControl(answers[i].trueFalseAnswer)
        })
      );
    }

    this.questionForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((r: MultiChoiceForm) => {
        const allCorrect: boolean = r.formArray.reduce(
          (allCorrect: boolean, i: MultiChoiceAnswer) => {
            if (allCorrect) {
              if (i.checked === null || i.checked === undefined) {
                return !i.answer.isCorrect;
              } else {
                return i.checked === i.answer.isCorrect;
              }
            }
            return allCorrect;
          },
          true
        );

        const multiChoiceAnswers: {[answerID: string]: UserAnswer} =
          r.formArray.reduce(
            (d: {[answerID: string]: UserAnswer}, i: MultiChoiceAnswer) => {
              d[i.answer.id] = {
                answer: i.answer,
                multipleChoiceChecked: i.checked,
                trueFalseAnswer: false
              };

              return d;
            },
            {}
          );

        this.recordedAnswer = {
          ...question,
          answers: multiChoiceAnswers,
          isAnswered: true,
          isCorrect: allCorrect
        };

        // this.answer.emit(this.recordedAnswer);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  submitAnswer() {
    if (this.recordedAnswer) {
      this.answer.emit(this.recordedAnswer);
    }
  }

  toggleCheckbox(i: number) {
    this.formArray
      ?.at(i)
      .get('checked')
      ?.setValue(!this.formArray?.at(i)?.get('checked')?.value);
  }

  get answerChoices() {
    return this.formArray.controls as FormGroup[];
  }

  get formArray() {
    return this.questionForm.get('formArray') as FormArray;
  }

  @Input()
  set question(q: QuestionWithAnswer | null | undefined) {
    // console.log(q);
    if (q) {
      this._onDestroy$.next(true);
      this.question$.next(q);
      this.init(q);
    }
  }
}
