import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {
  Answer,
  createMultichoiceAnswer,
  getReferenceLinksFromQuestion,
  Question,
  QuestionAction,
  QuestionPropertyAction,
  Quiz
} from '@gigasoftware/shared/api';
import {GSFirebaseUploadImageWithDocConfig} from '@gigasoftware/shared/media';
import {
  DlcInputImageConfig,
  ecQuizImageUploaderConfig,
  QuizQueryEngine,
  QuizzesEngineStore
} from '@gigasoftware/shared/store';
import {
  DlcAutoResizeTextAreaDirective,
  DlcAutoResizeTextareaModule,
  DlcFormStatus,
  DlcInputImageComponent,
  DlcInputLinkComponent,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent,
  ReferenceLinksService
} from '@gigasoftware/shared/ui-design-library';
import {Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-create-multiple-choice-answer'
  },
  imports: [
    CommonModule,
    DlcRoundedIconButtonComponent,
    DlcRoundedTextIconButtonComponent,
    DlcInputLinkComponent,
    DlcAutoResizeTextAreaDirective,
    DlcAutoResizeTextareaModule,
    DlcInputImageComponent,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule
  ],
  providers: [ReferenceLinksService],
  selector: 'ec-create-multiple-choice-answer',
  styleUrls: ['./ec-create-multiple-choice-answer.component.scss'],
  templateUrl: './ec-create-multiple-choice-answer.component.html'
})
export class EcCreateMultipleChoiceAnswerComponent implements OnInit {
  private quizStore: QuizzesEngineStore = inject(QuizzesEngineStore);

  private _onDestroy$: Subject<boolean> = new Subject();

  private question$: ReplaySubject<Question> = new ReplaySubject<Question>(1);

  // inputImageConfig: Signal<DlcInputImageConfig | null | undefined> = computed(
  //   () => {
  //     const question: Question | null = this.questionSignal();
  //
  //     if (question) {
  //       const docPath: string | undefined | null =
  //         this.qe()?.getQuestionFirestoreDocPath$(question);
  //
  //       if (docPath) {
  //         return {
  //           filenameWithoutExtension: question?.id || '',
  //           imagePath: question?.imagePath || '',
  //           uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
  //             ...ecQuizImageUploaderConfig,
  //             firestoreDoc: {
  //               // doc: question,
  //               docProperty: 'imagePath',
  //               firestoreDocPath: docPath
  //             }
  //           }
  //         };
  //       }
  //     }
  //
  //     return null;
  //   }
  // );

  @Output() deleteAction: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();
  inputImageConfig$: Observable<DlcInputImageConfig | null> =
    this.question$.pipe(
      switchMap((question: Question) => {
        return this.quizStore.selectCurrentQuizQueryEngine$.pipe(
          switchMap((qe: QuizQueryEngine | null | undefined) => {
            if (qe) {
              return qe.getQuestionFirestoreDocPath$(question);
            }

            return of(null);
          }),
          map((docPath: string | undefined | null) => {
            if (docPath) {
              return {
                filenameWithoutExtension: question?.id || '',
                imagePath: question?.imagePath || '',
                uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
                  ...ecQuizImageUploaderConfig,
                  firestoreDoc: {
                    // doc: question,
                    docProperty: 'imagePath',
                    firestoreDocPath: docPath
                  }
                }
              };
            }

            return null;
          })
        );
      })
    );

  inputImageConfig: Signal<DlcInputImageConfig | null> = <
    Signal<DlcInputImageConfig | null>
  >toSignal(this.inputImageConfig$);

  @Input() onSave: Observable<void> | undefined;
  qe: Signal<QuizQueryEngine | null | undefined> = <
    Signal<QuizQueryEngine | null | undefined>
  >toSignal(this.quizStore.selectCurrentQuizQueryEngine$);

  questionForm: FormGroup;

  @Input() questionPlaceholder = 'Type your question here ...';

  questionSignal: Signal<Question> = <Signal<Question>>toSignal(this.question$);
  @Input() quiz: Quiz | null | undefined = null;

  @Output() saveAction: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();

  @Output() status: EventEmitter<DlcFormStatus> =
    new EventEmitter<DlcFormStatus>();

  @Output() updateAction: EventEmitter<QuestionPropertyAction> =
    new EventEmitter<QuestionPropertyAction>();

  constructor(
    private fb: FormBuilder,
    public linksService: ReferenceLinksService
  ) {
    this.questionForm = this.fb.group({
      answers: this.fb.array([]),
      imagePath: new FormControl(null),
      links: this.linksService.array,
      question: new FormControl(null, [Validators.required])
    });
  }

  private getQuestionFromForm(question: Question): Question {
    const _question: Question = {
      ...question,
      imagePath: this.imagePathFormControl.value,
      question: this.questionForm.controls['question'].value,
      referenceLinks: this.linksService.values()
    };

    _question.answers = {};

    for (let i = 0; i < this.answers.length; i++) {
      const answer = this.answers.at(i).value;

      _question.answers[answer.id] = answer;
    }

    delete _question.referenceLink;

    return _question;
  }

  addAnswerForm(answer: Answer | null = null): void {
    answer = answer ? answer : createMultichoiceAnswer();
    this.answers.push(
      this.fb.group({
        answerText: new FormControl(answer.answerText, [Validators.required]),
        id: new FormControl(answer.id),
        isCorrect: new FormControl(answer.isCorrect)
      })
    );
  }

  addLink() {
    this.linksService.addLink({label: '', url: ''});
  }

  deleteAnswer(index: number): void {
    this.answers.removeAt(index);
    this.questionForm.markAsDirty({onlySelf: true});
  }

  deleteLink(linkIndex: number) {
    this.referenceLinks.markAsDirty();
    this.linksService.removeAt(linkIndex);
  }

  getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }

  ngOnInit(): void {
    this.questionForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.status.emit({
          dirty: this.questionForm.dirty,
          invalid: this.questionForm.invalid,
          pristine: this.questionForm.pristine,
          valid: this.questionForm.valid
        });
      });

    if (this.onSave) {
      this.onSave.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this.saveQuestion();
      });
    }

    const question: Question | null = this.questionSignal();

    if (question) {
      const answers: Answer[] = Object.values(question.answers);

      this.linksService.setLinks(getReferenceLinksFromQuestion(question));

      // Add a blank link
      // No links exist
      if (this.linksService.array.length === 0) {
        this.addLink();
      }

      this.questionForm.patchValue({
        question: question.question
      });

      if (answers.length > 0) {
        this.answers.clear();

        for (let i = 0; i < answers.length; i++) {
          this.addAnswerForm(answers[i]);
        }
      } else {
        this.addAnswerForm();
      }
    }
  }

  onDelete() {
    // TODO delete image
    const question: Question | null = this.questionSignal();

    if (this.quiz && question) {
      this.deleteAction.emit({
        question: question,
        quiz: this.quiz
      });
    }
  }

  onDeleteImage() {
    const question: Question | null = this.questionSignal();

    if (question && this.quiz) {
      this.imagePathFormControl.setValue(null);

      const _question: Question = this.getQuestionFromForm(question);

      this.updateAction.emit({
        question: _question,
        quiz: this.quiz,
        update: <Pick<Question, 'imagePath'>>{
          imagePath: null
        }
      });
    }
  }

  /**
   * Saving Question, image, reference links, and answers.
   * This also requires update the parent quiz.
   */
  saveQuestion() {
    const question: Question | null = this.questionSignal();

    if (this.questionForm.valid && this.quiz && question) {
      const _question: Question = this.getQuestionFromForm(question);

      this.saveAction.emit({
        question: _question,
        quiz: this.quiz
      });
    }
  }

  get answerFormGroups() {
    return this.answers.controls as FormGroup[];
  }

  get answers() {
    return this.questionForm.get('answers') as FormArray;
  }

  // undoAnswer(index: number, answerGroup: UntypedFormGroup) {
  //   const question: Question | null = this.questionSignal();
  //   if (question) {
  //     const answerGroupValue = answerGroup.value;
  //     const answer: AbstractControl = this.answers.at(index);
  //
  //     answer.reset(question.answers[answerGroupValue.id]);
  //   }
  // }

  // clearAnswer(index: number) {
  //   const answer = this.answers.at(index) as UntypedFormGroup;
  //
  //   answer.controls['answerText'].setValue(null);
  //   answer.controls['isCorrect'].setValue(true);
  //   answer.controls['answerText'].markAsDirty({onlySelf: true});
  //   answer.controls['isCorrect'].markAsDirty({onlySelf: true});
  // }

  // undoQuestion() {
  //   const question: Question | null = this.questionSignal();
  //
  //   if (question) {
  //     this.questionForm.controls['question'].reset(question.question);
  //   }
  // }

  // undoReferenceLink(linkIndex: number) {
  //   this.getQuestionOnce().subscribe((question: Question) => {
  //     this.linksService.undoReferenceLink(linkIndex, question);
  //   });
  // }

  // clearQuestion() {
  //   this.questionForm.controls['question'].setValue(null);
  //   this.questionForm.controls['question'].markAsDirty({onlySelf: true});
  // }

  get imagePathFormControl() {
    return this.questionForm.get('imagePath') as FormControl;
  }

  @Input()
  set question(question: Question | null | undefined) {
    if (question) {
      this.question$.next(question);
    }
  }

  get referenceLinks() {
    return this.questionForm.get('links') as FormArray;
  }
}
