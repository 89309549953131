import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {CollaborativeEntity, EC_QUIZ_ROLES} from '@gigasoftware/shared/api';
import {
  DlcRoundedTextButtonComponent,
  expandEnterAnimation
} from '@gigasoftware/shared/ui-design-library';
import {LetDirective, PushPipe} from '@ngrx/component';
import {Subject} from 'rxjs';

import {JoinDialogData} from './join-group-with-code-dialog.model';
import {JoinGroupWithCodeDialogService} from './join-group-with-code-dialog.service';

@Component({
  animations: [expandEnterAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-join-group-with-code-dialog'
  },
  imports: [
    CommonModule,
    DlcRoundedTextButtonComponent,
    LetDirective,
    MatProgressBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    PushPipe,
    ReactiveFormsModule
  ],
  providers: [JoinGroupWithCodeDialogService],
  selector: 'ec-join-group-with-code-dialog',
  styleUrls: ['./join-group-with-code-dialog.component.scss'],
  templateUrl: './join-group-with-code-dialog.component.html'
})
export class JoinGroupWithCodeDialogComponent implements OnDestroy, OnInit {
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  public cmpSvc: JoinGroupWithCodeDialogService = inject(
    JoinGroupWithCodeDialogService
  );
  private _dialog: MatDialogRef<JoinGroupWithCodeDialogComponent> =
    inject(MatDialogRef);
  public data: JoinDialogData = inject(MAT_DIALOG_DATA);

  private _onDestroy$: Subject<boolean> = new Subject();
  joinFormGroup: UntypedFormGroup = this._fb.group({
    joinCode: new UntypedFormControl(null, [Validators.required]),
    role: new UntypedFormControl(EC_QUIZ_ROLES.Student, [Validators.required])
  });

  joinGroup() {
    this.cmpSvc
      .joinGroup(this.joinFormGroup.value)
      .subscribe((p: CollaborativeEntity | null) => {
        this.cmpSvc.setInProgress(false);
        if (p) {
          this._dialog.close(p);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
  }

  ngOnInit() {
    this.cmpSvc.setEntityType(this.data.entityTypeID);
  }
}
