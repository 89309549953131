import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Classroom} from '@gigasoftware/shared/api';
import {selectLoggedInUserIsClassroomOwner} from '@gigasoftware/shared/store';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-class-list-item'
  },
  imports: [
    CommonModule,
    DlcImageDirective,
    DlcRoundedIconButtonComponent,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatMenuModule
  ],
  selector: 'ec-class-list-item, [ec-class-list-item]',
  styleUrls: ['./ec-class-list-item.component.scss'],
  templateUrl: './ec-class-list-item.component.html'
})
export class EcClassListItemComponent {
  private store: Store = inject(Store);

  @Input() classroom!: Classroom;
  @Output() doDeleteItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();
  @Output() doEditItem: EventEmitter<Classroom> = new EventEmitter<Classroom>();
  @Output() doLeaveItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();
  @Output() doSelectItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  loggedInUserIsOwner: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsClassroomOwner
  );

  isPlural(uids: string[] | null | undefined) {
    return uids !== null && uids !== undefined && uids.length > 1;
  }

  onDeleteClassroom() {
    this.doDeleteItem.emit(this.classroom);
  }

  onEditClassroom() {
    this.doEditItem.emit(this.classroom);
  }

  onLeaveClassroom() {
    this.doLeaveItem.emit(this.classroom);
  }

  // onSelectItem(e: MouseEvent, p: CollaborativeEntity) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //
  //   this.doSelectItem.emit(p);
  // }

  // onSelectEntity(e: MouseEvent, p: CollaborativeEntity) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //
  //   this.doSelectEntity.emit(p);
  // }

  // delete() {
  //   const that = this;
  //   this.entity$
  //     .pipe(withLatestFrom(this._custom.user$), take(1))
  //     .subscribe(([p, user]) => {
  //       const ref: MatDialogRef<EcConfirmDeleteDialogComponent> =
  //         this._dialog.open(EcConfirmDeleteDialogComponent, {
  //           data: p
  //         });
  //       ref.afterClosed().subscribe(doDelete => {
  //         if (doDelete) {
  //           this.showProgressBar.emit(true);
  //
  //           if (user.uid) {
  //             const path = firestoreQueryPathByEntity(p, user.uid);
  //
  //             that._custom.recursiveDelete$(path).subscribe(
  //               () => {
  //                 that.showProgressBar.emit(false);
  //               },
  //               () => {
  //                 that.showProgressBar.emit(false);
  //               }
  //             );
  //           }
  //         }
  //       });
  //     });
  // }

  // leaveClass() {
  //   const that = this;
  //   this.entity$
  //     .pipe(withLatestFrom(this._custom.user$), take(1))
  //     .subscribe(([p, user]) => {
  //       const ref: MatDialogRef<PlatConfirmLeaveDialogComponent> =
  //         this._dialog.open(PlatConfirmLeaveDialogComponent, {
  //           backdropClass: 'dlc-global-dialog-background',
  //           data: p
  //         });
  //       ref.afterClosed().subscribe(doLeave => {
  //         if (doLeave) {
  //           this.showProgressBar.next(true);
  //
  //           const _p = removeUserAndRoleFromEntity(
  //             JSON.parse(JSON.stringify(p)),
  //             user.uid
  //           );
  //
  //           if (user.uid) {
  //             const path = firestoreQueryPathByEntity(_p, user.uid);
  //
  //             that._custom
  //               .update$(path, {
  //                 members: _p.members,
  //                 memberUIDs: _p.memberUIDs
  //               })
  //               .subscribe(
  //                 () => {
  //                   that.showProgressBar.next(false);
  //                 },
  //                 () => {
  //                   that.showProgressBar.next(false);
  //                 }
  //               );
  //           }
  //         }
  //       });
  //     });
  // }

  onSelectClassroom() {
    this.doSelectItem.emit(this.classroom);
  }
}
